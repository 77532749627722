<template lang="pug">
.page
  | this is a vue page file with basic api
.content
  | this is page content

</template>

<script setup>
import { inject, onMounted } from 'vue'

const foo = 'bar'

</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
</style>
